module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Venzael's Astroguide","short_name":"Venzael's Astroguide","start_url":"/","background_color":"#D1D1D1","theme_color":"#D1D1D1","display":"standalone","icon":"assets/gamedata/SuperDagonD_large.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2f3a8d55c2d8aeba2ebf1de64446cb83"},
    },{
      plugin: require('../node_modules/gatsby-plugin-firebase/gatsby-browser.js'),
      options: {"plugins":[],"features":{"analytics":true},"credentials":{"apiKey":"AIzaSyAwxX2FHVoVmqoj8pPn4JLS9fQEMuKZF_c","authDomain":"msl-site.firebaseapp.com","databaseURL":"https://msl-site.firebaseio.com","projectId":"msl-site","storageBucket":"msl-site.appspot.com","messagingSenderId":"597137177466","appId":"1:597137177466:web:197e7b9e73e4d2515034c3","measurementId":"G-LG4S1JWEZ9"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
